import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";
import SingleServicePage from "../containers/Home/pages.style";
import Seo from "components/seo";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import TitleBar2 from "common/components/Titlebar2";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const WigforMen = () => {
  const seoData = {
    title: "Wigs for Men in Delhi - Radiance Hair Studio",
    description:
      "Overcoming baldness is now easier than ever with original human Wigs for Men in Delhi at Radiance. Regain your confidence, book an appointment today.",
    keywords: ["Wigs for Men in Delhi"],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "areaServed": "New Delhi",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "disambiguatingDescription": "Wigs for Men in Delhi",
    "additionalType": "https://www.radiancehairstudio.com/wigs-for-men.php",
    "category": "https://www.radiancehairstudio.com/wigs-for-men.php",
    "description": "Overcoming baldness is now easier than ever with original human Wigs for Men in Delhi at Radiance. Regain your confidence, book an appointment today.",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Wig-for-Men.jpg",
      "https://www.radiancehairstudio.com/hair-wig-for-men1.png",
      "https://www.radiancehairstudio.com/hair-wig-for-men2.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "serviceType": "Wig for Men's"
  };   

  // const faqs = [
  //   {
  //     title: "How much does a wig cost?",
  //     description:
  //       "The cost of a wig depends on the type you choose. Synthetic Wig cost Cheaper than natural human hair wigs.",
  //   },
  //   {
  //     title: "What to choose synthetic Wig or human hair wig?",
  //     description:
  //       "Synthetic hair wigs usually project a false unnatural look, where the natural human hair wigs blend week to create a natural hair look.",
  //   },
  //   {
  //     title: "Can I style my Wig?",
  //     description:
  //       "You can style your wig according to your personality without any hustle. Natural human hair wigs are made out of natural human hair thus is easy to style.",
  //   },
  //   {
  //     title: "For how long will a wig last?",
  //     description:
  //       "Everything needs care, so with the scenario with wigs. With proper care and use, a wig can last you for months or maybe a year.",
  //   },
  //   {
  //     title: "Can I color my wig hair?",
  //     description:
  //       "You can color your wig hair any time and reflect your style accordingly to match up your personality. But excessive use of the chemical can be ruining sometimes.",
  //   },
  // ];

  // const faqs33 = [
  //   {
  //     title: "Do I need a wig if facing partial hair loss or thinning hair?",
  //     description:
  //       "There are several wigs available that potentially hide the thinning hairline or hair loss areas and one can hardly identify the wig as it blends seamlessly with your existing hair. It makes your hair looks flawless and gorgeous. Moreover, with our wigs what is made of 100% natural human hair, you will have a natural feel and finish.",
  //   },
  //   {
  //     title: "Can I style my Wig?",
  //     description:
  //       "You can of course style your wig according to your choice. But as maintenance is an important step, it is advisable not to be harsh on the wig you wear. RADIANCE also offers with a huge collection of premium pre-customized wigs for customers.",
  //   },
  //   {
  //     title: "What quality wigs does Radiance provide?",
  //     description:
  //       "Though we have a collection of all kinda wigs in high quality, we always do recommend and assist our customers to go for wigs what is exclusively made of 100% natural human hair. Here in Radiance, we will customize your wig as per your preference.",
  //   },
  //   {
  //     title: "What color should I choose? Can I customize it?",
  //     description:
  //       "We have a catalog of wigs specifically for styles and colors. Moreover, our hair experts will guide you to pick a proper color and perfect style that suits you the most. And yes, we will customize the color of the wig for you as per your own choice and it will be completely looked like your natural hair.",
  //   },
  // ];

  // const swiperBreakpoints = {
  //   // When viewport width is less than or equal to 767px (typical mobile size)
  //   767: {
  //     slidesPerView: 4,
  //     spaceBetween: 20, 
  //   },
  //   // When viewport width is greater than 767px (larger screens)
  //   768: {
  //     slidesPerView: 4,
  //     spaceBetween: 20,
  //   },
  // };

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Wig-for-Men.jpg",
      content: (
        <div>
          <h2>Hair Wig for Men's</h2>
          <p>
            When it comes to looking good and confident, the feminine beauty is
            the TALK OF THE TOWN! But why should that be the scenario and MENS
            FASHION be less appreciated? Confidence is for all. RADIANCE studio
            understands that men also fall prey to alopecia. We step our best foot
            forward aim equity! RADIANCE studio provides the hair wigs in Delhi
            for MEN!
          </p>
          <h2>Radiance Hits...</h2>
          <p>
            Coming straight to the point of WHY WIGS? Because hair contributes to
            the personality we choose to carry. The male pattern baldness is the
            most common hair fall problem faced by men. Irrespective of the roots
            that lead to such a concerning issue, we focus our mind on solving the
            same. RADIANCE studio offers <a href="/hair-weaving-for-mens.php" style={{ color: "#373737"}}>hair wigs for men</a> in Delhi. Customization
            of wigs according to the customer's problem with 100% natural human
            hair is what makes the RADIANCE stand apart. Also the individuality of
            the hair color, texture and type are taken care of.
          </p>
          <p>
            Radiance salon offers varied hair wigs for men in Delhi. Each
            customized to fight different stages of alopecia in men. In the case
            of hair thinning the wig usually offer for men is named TOUPEE!
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/hair-wig-for-men1.png",
      largeImg: "/hair-wig-for-men2.png",
      content: (
        <div>
          <h2>The Booming Benefits!</h2>
          <p>
            Style reflects the CHARACTER of an individual. When it comes to MEN, their personality should hold a charge. The hair is something that can change an entire look. Some men choose to go bald as a style but for those who do not, here are some great benefits for them.
          </p>
          <ul    >
            <li><strong>Pro STYLE</strong> -  If you are the one crazy <a href="/about.php" style={{ color: "#373737"}}>about</a> fashion, WIGS have to be your best friend! Styling with trending colors and experimenting the best face forward, Wigs being out the best of you. </li>
            <li> <strong>Protection</strong> - Wigs contributes to the protection of the hair. Saving it from all the outer world solution and maintaining that beautiful MANE inside.</li>
            <li> <strong>Natural looks</strong> - Human hair wigs are 100% natural and thus blends perfectly with the natural existing mane projecting a natural shiny and bouncy hair look. </li>
            <li><strong>Long Life</strong> - At RADIANCE SALON we believe that the human hair wigs are a one-time investment. Buying one with a little good money can last you for years.</li>
            <li><strong>Maintenance</strong> - At RADIANCE we offer the best quality of hair to make the WIGS. With human hair styling becomes easy. No extra maintenance is required to reflect all your style. The wigs can be colored, styled, heated all according to fashion preferences.</li>
          </ul>
          <p>
            RADIANCE studio assures to offer the best hair wigs for men in Delhi. We welcome you with a free consultation from our hair experts.
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "The FAQs",
      description:
        "Are you too are looking for a major transformation but afraid to experiment, WIGS are bound to be your best friend! But in case there is a certain question that is bothering you here are some most commonly asked questions we answer.",
      description2: "By now we may have convinced the MEN out there to boost up their confidence and get themselves a well-deserved MAKEOVER. Our experts are always to your help at RADIANCE SALON. You may visit us for the best hair wigs in. Delhi.",
      faq: [
        {
          title: "How much does a wig cost?",
          description:
            "The cost of a wig depends on the type you choose. Synthetic Wig cost Cheaper than natural human hair wigs.",
        },
        {
          title: "What to choose synthetic Wig or human hair wig?",
          description:
            "Synthetic hair wigs usually project a false unnatural look, where the natural human hair wigs blend week to create a natural hair look.",
        },
        {
          title:
            "Can I style my Wig?",
          description:
            "You can style your wig according to your personality without any hustle. Natural human hair wigs are made out of natural human hair thus is easy to style.",
        },
        {
          title:
            "For how long will a wig last?",
          description:
            "Everything needs care, so with the scenario with wigs. With proper care and use, a wig can last you for months or maybe a year.",
        },
        {
          title:
            "Can I color my wig hair?",
          description:
            "You can color your wig hair any time and reflect your style accordingly to match up your personality. But excessive use of the chemical can be ruining sometimes.",
        },
      ],
    },
  
  ];  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Wig for Men's" banner="/service-images/3.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Hair Wig for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default WigforMen;
